import Vue from 'vue'

const state = {
  // General
  name: '',
  collection: 'profiles',
  parentCollection: '',
  schema: null,
  selectedCollection: 'profiles',
  dropzone: null,
  file: null,
  hasHeader: true,
  actionMode: 'create-update',
  emptyMode: 'ignore',
  keyField: '',
  keyFields: [],
  delimiter: null,
  linebreak: null,
  parentKeys: [],
  parentMappings: [],
  mappings: {}
}

// getters
const getters = {
  // General
  name: (state) => { return state.name },
  collection: (state) => { return state.collection },
  parentCollection: (state) => { return state.parentCollection },
  schema: (state) => { return state.schema },
  selectedCollection: (state) => { return state.selectedCollection },
  dropzone: (state) => { return state.dropzone },
  file: (state) => { return state.file },
  hasHeader: (state) => { return state.hasHeader },
  actionMode: (state) => { return state.actionMode },
  emptyMode: (state) => { return state.emptyMode },
  keyField: (state) => { return state.keyField },
  keyFields: (state) => { return state.keyFields },
  parentKeyFields: (state) => { return state.parentKeyFields },
  delimiter: (state) => { return state.delimiter },
  linebreak: (state) => { return state.linebreak },
  parentKeys: (state) => { return state.parentKeys },
  parentMappings: (state) => { return state.parentMappings },
  mappings: (state) => { return state.mappings }
}

// actions
const actions = {
}

// mutations
const mutations = {
  // General
  setName (state, val) { state.name = val },
  setCollection (state, val) { state.collection = val },
  setParentCollection (state, val) { state.parentCollection = val },
  setSchema (state, val) { Vue.set(state, 'schema', val) },
  setSelectedCollection (state, val) { state.selectedCollection = val },
  setDropzone (state, val) { Vue.set(state, 'dropzone', val) },
  setFile (state, val) { Vue.set(state, 'file', val) },
  setHasHeader (state, val) { state.hasHeader = val },
  setActionMode (state, val) { state.actionMode = val },
  setEmptyMode (state, val) { state.emptyMode = val },
  setKeyField (state, val) { state.keyField = val },
  setKeyFields (state, val) { state.keyFields = val },
  setParentKeyFields (state, val) { state.keyParentFields = val },
  setDelimiter: (state, val) => { state.delimiter = val },
  setLinebreak: (state, val) => { state.linebreak = val },
  setParentKeys (state, val) { Vue.set(state, 'parentKeys', val) },
  setParentMappings (state, val) { Vue.set(state, 'parentMappings', val) },
  setMappings (state, val) { Vue.set(state, 'mappings', val) }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
