// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Toastr from 'vue-toastr'
import App from './App'
import router from './router'
import store from './store'

import user from '@/plugins/user'

// import 'whatwg-fetch'
// Does this even work?
// import '@/assets/js/local-storage.polyfill.js'
import 'babel-polyfill'

import '@/lib/draggable.js'

// TODO: Fix this! It can't be found?!?!?
// import 'es6-promise/auto'

import '@/assets/styles/main.scss'
import 'vue-toastr/src/vue-toastr.scss'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Add some FontAwesome fonts
import '@/lib/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import InlineEditor from '@ckeditor/ckeditor5-build-inline'
// window.CKEDITOR = InlineEditor

// this is used later in the resizing and gesture demos
//  window.dragMoveListener = dragMoveListener;

Vue.component('fa-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(user)

// Add toastr
Vue.use(Toastr, {
  defaultPosition: 'toast-top-center',
  defaultProgressBar: false
})

Vue.config.productionTip = false
Vue.config.performance = true

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  user,
  components: { App },
  template: '<App/>'
})
