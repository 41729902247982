import Vue from 'vue'

const state = {
  name: '',
  segmentId: '',
  publishedAt: '',
  steps: { }
}

// getters
const getters = {
  name: (state) => state.name,
  publishedAt: (state) => state.publishedAt,
  segmentId: (state) => state.segmentId,
  stepsById: (state) => state.steps,

  getStepById: (state) => (id) => {
    return state.steps[id]
  },
  getStepByChildId: (state) => (childId) => {
    for (var i in state.steps) {
      if (state.steps[i].nextId && state.steps[i].nextId === childId) {
        return state.steps[i]
      } else if (state.steps[i].nextIdTrue && state.steps[i].nextIdTrue === childId) {
        return state.steps[i]
      } else if (state.steps[i].nextIdFalse && state.steps[i].nextIdFalse === childId) {
        return state.steps[i]
      }
    }
  }
}

// actions
const actions = {
}

// mutations
const mutations = {
  clearAll (state) {
    Vue.set(state, 'name', '')
    Vue.set(state, 'segmentId', '')
    Vue.set(state, 'publishedAt', '')
    Vue.set(state, 'steps', {})
  },
  setName (state, name) { state.name = name },
  setPublishedAt (state, publishedAt) { state.publishedAt = publishedAt },
  setSegmentId (state, publishedAt) { state.segmentId = publishedAt },

  setSteps (state, steps) {
    for (let i in steps) {
      if (typeof steps[i].values === 'undefined') {
        steps[i].values = {}
      }
    }
    Vue.delete(state, 'steps')
    Vue.set(state, 'steps', steps)
  },
  setStepValue (state, payload) {
    // Note: It could also be done by "replacing" the entire condition, but that
    // might trigger a lot of change events, that we don't want
    if (payload.name === 'segql') {
      state.steps[payload.id]['segmentId'] = ''
    } else if (payload.name === 'segmentId') {
      state.steps[payload.id]['segql'] = ''
    }

    Vue.set(state.steps[payload.id], payload.name, payload.value)
    // state.steps[payload.id][payload.name] = payload.value
  },
  addStep (state, step) {
    if (typeof step.values === 'undefined') {
      step.values = {}
    }
    Vue.set(state.steps, step.id, step)
    // state.conditions[condition.id] = condition
  },

  deleteStep (state, stepId) {
    let getStepByChildId = getters.getStepByChildId(state)
    let step = state.steps[stepId]

    let parentNextId = ''

    // Check if we need to delete all child steps
    if (!step.nextId) {
      // We need to delete this AND it's children
      mutations.deleteChildSteps(state, stepId)
      parentNextId = ''
    } else {
      // We can delete this step only, but we need to change the nextId of the parent
      parentNextId = step.nextId
    }

    let parentStep = getStepByChildId(stepId)
    if (parentStep.nextId && parentStep.nextId === stepId) {
      mutations.setStepValue(state, {id: parentStep.id, name: 'nextId', value: parentNextId})
    } else if (parentStep.nextIdTrue && parentStep.nextIdTrue === stepId) {
      mutations.setStepValue(state, {id: parentStep.id, name: 'nextIdTrue', value: parentNextId})
    } else if (parentStep.nextIdFalse && parentStep.nextIdFalse === stepId) {
      mutations.setStepValue(state, {id: parentStep.id, name: 'nextIdFalse', value: parentNextId})
    }

    Vue.delete(state.steps, stepId)
  },

  deleteChildSteps (state, stepId) {
    let parentStep = state.steps[stepId]

    // ALSO: UPDATE AND REMOVE NEXTID(TRUE|FALSE)

    for (var i in state.steps) {
      if (parentStep.nextId && parentStep.nextId === state.steps[i].id) {
        mutations.deleteChildSteps(state, parentStep.nextId)
      } else if (parentStep.nextIdTrue && parentStep.nextIdTrue === state.steps[i].id) {
        mutations.deleteChildSteps(state, parentStep.nextIdTrue)
      } else if (parentStep.nextIdFalse && parentStep.nextIdFalse === state.steps[i].id) {
        mutations.deleteChildSteps(state, parentStep.nextIdFalse)
      }
    }

    Vue.delete(state.steps, stepId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
