// import Vue from 'vue'

const state = {
  themeId: '',
  name: '',
  type: '',
  html: '',
  mjml: '',
  text: ''
}

// getters
const getters = {
  // id: (state) => state.id,
  name: (state) => state.name,
  type: (state) => state.type,
  html: (state) => state.html,
  mjml: (state) => state.mjml,
  text: (state) => state.text,
  textValue: (state) => state.text,
  theme: (state) => state.theme,
  components: (state) => state.components
}

// actions
const actions = {
}

// mutations
const mutations = {
  setEmail (state, email) {
    // state.id = email.id
    state.name = email.name
    state.themeId = email.themeId
    state.type = email.type
    state.html = email.html
    state.mjml = email.mjml
    state.text = email.text
  },
  setName (state, name) { state.name = name },
  setHtml (state, html) { state.html = html },
  setMjml (state, mjml) { state.mjml = mjml },
  setText (state, text) { state.text = text },
  setTheme (state, theme) { state.theme = theme },
  setComponents (state, components) { state.components = components }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
