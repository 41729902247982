import Vue from 'vue'

// Should conditions have an "id" which is just the index?
// Maybe actually assign a uuid to them?
// It seems like we do need some kind of id, to be able to delete from the store, etc...
// conditions: [{ parentId, schema, compareType, matchType, matchProperty, compareValue, children }]
const state = {
  name: '',
  schemas: {},
  root: {
    'id': 'root',
    'negation': false,
    'option': 'all',
    'path': [],
    'type': '@fsSegmentsBuilder/PROFILE_GROUP',
    'value': [] // Don't know why this was originally an array...
  },
  conditions: {}
}

// getters
const getters = {
  // root // Should root also hold a children property? Or is parentId === 0 enough?
  // childrenOf(parentId)
  // condition(index)

  name: (state) => { return state.name },
  schemas: (state) => { return state.schemas },
  getConditionById: (state) => (id) => { return state.conditions[id] },

  // parentId will currently be a randomly generated uuid
  getChildrenOf: (state) => (parentId) => {
    let children = {}
    for (let i in state.conditions) {
      if (state.conditions[i].parentId === parentId) {
        children[i] = state.conditions[i]
      }
    }
    return children
  },

  all: (state) => {
    return {
      root: state.root,
      conditions: state.conditions
    }
  },

  root: (state) => { return state.root },
  conditions: (state) => { return state.conditions }
}

// actions
const actions = {
}

// mutations
const mutations = {
  clearAll (state) {
    Vue.set(state, 'name', '')
    Vue.set(state, 'root', {
      'id': 'root',
      'negation': false,
      'option': 'all',
      'path': [],
      'type': '@fsSegmentsBuilder/PROFILE_GROUP',
      'value': [] // Don't know why this was originally an array...
    })
    Vue.set(state, 'conditions', {})
  },
  setName (state, name) {
    state.name = name
  },
  setRoot (state, root) {
    Vue.delete(state, 'root')
    Vue.set(state, 'root', {})
    for (var i in root) {
      state.root[i] = root[i]
    }
  },
  setConditions (state, conditions) {
    Vue.delete(state, 'conditions')
    Vue.set(state, 'conditions', conditions)
  },
  addCondition (state, condition) {
    Vue.set(state.conditions, condition.id, condition)
  },
  setSchemas (state, schemas) {
    Vue.delete(state, 'schemas')
    Vue.set(state, 'schemas', schemas)
  },
  setConditionValue (state, payload) {
    // Note: It could also be done by "replacing" the entire condition, but that
    // might trigger a lot of change events, that we don't want
    state.conditions[payload.id][payload.name] = payload.value
  },

  deleteChildConditions (state, parentId) {
    console.log(parentId)
  },

  deleteCondition (state, conditionId) {
    for (var i in state.conditions) {
      let condition = state.conditions[i]
      if (condition.parentId === conditionId) {
        mutations.deleteCondition(state, condition.id)
      }
    }
    Vue.delete(state.conditions, conditionId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
