// Add some FontAwesome fonts
// All can be added with:
// import { fab } from '@fortawesome/free-brands-svg-icons'
// library.add(fab)
// But doing it one-by-one enables tree-shaking

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowsAltH,
  faBan,
  faBars,
  faBirthdayCake,
  faCalendarDay,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartPie,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCog,
  faCopy,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faExternalLinkAlt,
  faFileImport,
  faHandPointer,
  faKeyboard,
  faListOl,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faMapPin,
  faMinus,
  faPaperPlane,
  faPen,
  faPlug,
  faPlus,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faRandom,
  faRedo,
  faRedoAlt,
  faSearch,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faTable,
  faTachometerAlt,
  faThumbsDown,
  faThumbtack,
  faTimes,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTrashAlt,
  faTruckLoading,
  faTty,
  faUnlink,
  faUserLock,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons'

import {
  faCheckCircle,
  faCheckSquare,
  faClock,
  faPlusSquare,
  faMinusSquare
} from '@fortawesome/free-regular-svg-icons'

// TODO: These are probably names something else than fas-facebook-f
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'

// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowsAltH,
  faBan,
  faBars,
  faBirthdayCake,
  faCalendarDay,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCog,
  faCopy,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faExternalLinkAlt,
  faFacebookF,
  faFileImport,
  faHandPointer,
  faKeyboard,
  faLinkedinIn,
  faListOl,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faMapPin,
  faMinus,
  faMinusSquare,
  faPaperPlane,
  faPen,
  faPlug,
  faPlus,
  faPlusSquare,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faRandom,
  faRedo,
  faRedoAlt,
  faSearch,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faTable,
  faTachometerAlt,
  faThumbsDown,
  faThumbtack,
  faToggleOff,
  faToggleOn,
  faTimes,
  faTrash,
  faTrashAlt,
  faTruckLoading,
  faTty,
  faTwitter,
  faUnlink,
  faUserLock,
  faUser,
  faUsers
)
