import decode from 'jwt-decode'

let user = {
  ACCESS_TOKEN_KEY: 'jwtToken',

  logout () {
    sessionStorage.removeItem(this.ACCESS_TOKEN_KEY)
  },

  isAuthenticated () {
    let tokenStr = this.getAuthorizationToken()
    let token = this.verifyToken(tokenStr)

    return token !== null
  },

  // Decode the tokenStr, check the expiration and return a token object or null
  verifyToken (tokenStr) {
    if (typeof tokenStr !== 'string' || tokenStr === '') {
      return null
    }

    let token = ''
    try {
      token = decode(tokenStr)
    } catch (e) {
      return null
    }

    if (!token.exp) { return null }

    const date = new Date(0)
    const expirationDate = date.setUTCSeconds(token.exp)

    if (expirationDate < new Date()) {
      return null
    }

    return token
  },

  getAuthorizationToken () {
    return sessionStorage.getItem(this.ACCESS_TOKEN_KEY)
  },

  getUser () {
    let tokenStr = this.getAuthorizationToken()
    let token = this.verifyToken(tokenStr)
    return token
  },

  showBeta () {
    let tokenStr = this.getAuthorizationToken()
    let token = this.verifyToken(tokenStr)
    return (token && token.beta)
  },

  showExperimental () {
    let tokenStr = this.getAuthorizationToken()
    let token = this.verifyToken(tokenStr)
    return (token && token.experimental)
  }
}

export default {
  install (Vue) {
    Vue.prototype.$user = user
  }
}
