const state = {
  // General
  autoHideMenu: null
}

// getters
const getters = {
  // General
  autoHideMenu: (state) => { return state.autoHideMenu }
}

// mutations
const mutations = {
  // General
  setAutoHideMenu (state, val) { state.autoHideMenu = val }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
