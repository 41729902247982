import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/lib/auth'

const Dashboard = () => import('@/components/Pages/Dashboard')

const EmailsList = () => import('@/components/Pages/EmailsList')
const EmailsItem = () => import('@/components/Pages/EmailsItem')

const SendoutsList = () => import('@/components/Pages/SendoutsList')
const SendoutsItem = () => import('@/components/Pages/SendoutsItem')

const FilesList = () => import('@/components/Pages/FilesList')

const FlowsList = () => import('@/components/Pages/FlowsList')
const FlowsItem = () => import('@/components/Pages/FlowsItem')

const FlowTestsList = () => import('@/components/Pages/FlowTestsList')

const IntegrationsList = () => import('@/components/Pages/IntegrationsList')

const LoginPage = () => import('@/components/Pages/Login')
const ResetPasswordPage = () => import('@/components/Pages/ResetPassword')
const ForgotPasswordPage = () => import('@/components/Pages/ForgotPassword')
const ChangePasswordPage = () => import('@/components/Pages/ChangePassword')

const NotFound = () => import('@/components/Pages/NotFound')

const PermissionsList = () => import('@/components/Pages/PermissionsList')
// const PermissionsItem = () => import('@/components/Pages/PermissionsItem')

const Profile = () => import('@/components/Pages/Profile')
const ProfileData = () => import('@/components/Pages/ProfileData')
const ProfilesList = () => import('@/components/Pages/ProfilesList')
const ProfilesItem = () => import('@/components/Pages/ProfilesItem')
// const ProfileEventsList = () => import('@/components/Pages/ProfileEventsList')

const Import = () => import('@/components/Pages/Import')
const ImportsList = () => import('@/components/Pages/ImportsList')
const ImportsView = () => import('@/components/Pages/ImportsView')

const SegmentsList = () => import('@/components/Pages/SegmentsList')
const SegmentsItem = () => import('@/components/Pages/SegmentsItem')

const SchemasItem = () => import('@/components/Pages/SchemasItem')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '*', component: NotFound },
    { path: '/', name: 'frontpage', redirect: '/dashboard' },
    { path: '/dashboard', name: 'dashboard', component: Dashboard },

    { path: '/emails', name: 'emails', component: EmailsList },
    { path: '/emails/create', name: 'emailsCreate', component: EmailsItem },
    { path: '/emails/:id', name: 'emailsView', component: EmailsItem },
    { path: '/emails/edit/:id', name: 'emailsEdit', component: EmailsItem },
    { path: '/emails/copy/:id', name: 'emailsCopy', component: EmailsItem },

    { path: '/sendout', name: 'sendouts', component: SendoutsList },
    { path: '/emails/:emailId/sendout', name: 'sendoutCreate', component: SendoutsItem },
    // { path: '/sendout/create', name: 'sendoutCreate', component: SendoutsItem },
    // { path: '/sendout/:id', name: 'sendoutsView', component: SendoutsItem },

    { path: '/files', name: 'files', component: FilesList },

    { path: '/flows', name: 'flows', component: FlowsList },
    { path: '/flows/create', name: 'flowsCreate', component: FlowsItem },
    { path: '/flows/:id', name: 'flowsView', component: FlowsItem },
    { path: '/flows/edit/:id', name: 'flowsEdit', component: FlowsItem },

    { path: '/flowtests', name: 'flowTestsList', component: FlowTestsList },

    { path: '/integrations', name: 'integrations', component: IntegrationsList },

    { path: '/login', name: 'login', component: LoginPage },
    { path: '/forgot-password', name: 'forgotPassword', component: ForgotPasswordPage },
    { path: '/reset-password/:resetToken', name: 'resetPassword', component: ResetPasswordPage },
    { path: '/change-password', name: 'changePassword', component: ChangePasswordPage },

    { path: '/permissions', name: 'permissions', component: PermissionsList },
    // { path: '/permissions/create', name: 'permissionsCreate', component: PermissionsItem },
    // { path: '/permissions/:id', name: 'permissionsView', component: PermissionsItem },
    // { path: '/permissions/edit/:id', name: 'permissionsEdit', component: PermissionsItem },

    { path: '/import/:collection', name: 'import', component: Import },
    { path: '/imports', name: 'importsList', component: ImportsList },
    { path: '/imports/:id', name: 'importsView', component: ImportsView },

    { path: '/profiles', name: 'profiles', component: ProfilesList },
    { path: '/profiles/create', name: 'profilesCreate', component: ProfilesItem },
    { path: '/profiles/edit/:id', name: 'profilesEdit', component: ProfilesItem },
    { path: '/profiles/:id', name: 'profilesView', component: Profile },
    { path: '/profiles/data/:id', name: 'profilesData', component: ProfileData },
    // { path: '/profiles/:id', name: 'profilesView', component: ProfileEventsList },
    // { path: '/profiles/:id', name: 'profilesView', component: ProfilesItem },
    // { path: '/profiles/:profile_id/events_history', name: 'profileEventsView', component: ProfileEventsList },

    { path: '/segments', name: 'segments', component: SegmentsList },
    { path: '/segments/create', name: 'segmentsCreate', component: SegmentsItem },
    { path: '/segments/:id', name: 'segmentsView', component: SegmentsItem },
    { path: '/segments/edit/:id', name: 'segmentsEdit', component: SegmentsItem },

    { path: '/schemas/:id', name: 'schemasView', component: SchemasItem },
    { path: '/schemas/edit/:id', name: 'schemasEdit', component: SchemasItem }

    // TODO: Do this!
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }

  ]
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login' && !auth.isAuthenticated()) {
    return next()
  }

  if (to.name === 'resetPassword') {
    // if (auth.isAuthenticated()) {
    //   return next({name: 'frontpage'})
    // }
    return next()
  }

  if (to.name === 'forgotPassword') {
    // if (auth.isAuthenticated()) {
    //   return next({name: 'frontpage'})
    // }
    return next()
  }

  if (!auth.isAuthenticated()) {
    sessionStorage.removeItem('redirectUrl')

    let loc = window.location

    if (loc.pathname !== '/login') {
      let redirectUrl = `${loc.pathname}${loc.search}`
      sessionStorage.setItem('redirectUrl', redirectUrl)
    }

    return next({ name: 'login' })
  }

  next()
})

export default router
