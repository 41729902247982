import Vue from 'vue'

const state = {
  // Geneal
  name: '',
  emailId: '',

  // Profiles
  segmentId: '',
  onceOnly: false,
  removeDuplicates: false,
  duplicatePath: '',
  duplicateUse: 'newest',

  // Schedule
  sendType: 'now',
  sendDate: new Date(),
  sendTime: {
    HH: '10',
    mm: '00'
  },

  // Parameters
  subject: '',
  fromName: '',
  fromEmail: '',
  emailPath: '',
  emailType: 'marketing',
  permissions: []
}

// getters
const getters = {
  // General
  name: (state) => { return state.name },
  emailId: (state) => { return state.emailId },

  // Profiles
  segmentId: (state) => { return state.segmentId },
  onceOnly: (state) => { return state.onceOnly },
  removeDuplicates: (state) => { return state.removeDuplicates },
  duplicatePath: (state) => { return state.duplicatePath },
  duplicateUse: (state) => { return state.duplicateUse },

  // Schedule
  sendType: (state) => { return state.sendType },
  sendDate: (state) => { return state.sendDate },
  sendTime: (state) => { return state.sendTime },

  // Parameters
  subject: (state) => { return state.subject },
  fromName: (state) => { return state.fromName },
  fromEmail: (state) => { return state.fromEmail },
  emailPath: (state) => { return state.emailPath },
  emailType: (state) => { return state.emailType },
  permissions: (state) => { return state.permissions }
}

// actions
const actions = {
}

// mutations
const mutations = {
  // General
  setName (state, val) { state.name = val },
  setEmailId (state, val) { state.emailId = val },

  // Profiles
  setSegmentId (state, val) { state.segmentId = val },
  setOnceOnly (state, val) { state.onceOnly = val },
  setRemoveDuplicates (state, val) { state.removeDuplicates = val },
  setDuplicatePath (state, val) { state.duplicatePath = val },
  setDuplicateUse  (state, val) { state.duplicateUse = val },

  // Schedule
  setSendType (state, val) { state.sendType = val },
  setSendDate (state, val) {
    val.setHours(0)
    val.setMinutes(0)
    val.setSeconds(0)

    Vue.delete(state, 'sendDate')
    Vue.set(state, 'sendDate', val)
  },
  setSendTime (state, val) {
    Vue.delete(state, 'sendTime')
    Vue.set(state, 'sendTime', val)
  },

  // Parameters
  setSubject (state, val) { state.subject = val },
  setFromName (state, val) { state.fromName = val },
  setFromEmail (state, val) { state.fromEmail = val },
  setEmailPath (state, val) { state.emailPath = val },
  setEmailType (state, val) { state.emailType = val },
  setPermissions (state, val) {
    Vue.delete(state, 'permissions')
    Vue.set(state, 'permissions', val)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
