import Vue from 'vue'
import Vuex from 'vuex'
import queryBuilder from './modules/query-builder'
import flowBuilder from './modules/flow-builder'
import contentBuilder from './modules/content-builder'
import sendout from './modules/sendout'
import globals from './modules/globals'
import importCSV from './modules/import-csv'

Vue.use(Vuex)

let store = new Vuex.Store({
  modules: {
    globals,
    queryBuilder,
    flowBuilder,
    contentBuilder,
    sendout,
    importCSV
  },
  state: {
    name: ''
  },
  getters: {
    name: (state) => state.name
  },
  mutations: {
    setName (state, name) {
      state.name = name
    }
  }
})

export default store
