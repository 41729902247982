import Vue from 'vue'
import interact from 'interactjs'

var dragEvent = new Event('drag')

Vue.directive('draggable', {
  bind: function (el) {
    el.style.position = 'absolute'
    el.style.zIndex = '100'

    el.style.webkitTouchCallout =
    el.style.webkitUserSelect =
    el.style.khtmlUserSelect =
    el.style.mozUserSelect =
    el.style.msUserSelect =
    el.style.userSelect = 'none'

    interact(el).draggable({
      inertia: false,
      autoScroll: true,
      onstart: function (event) {
        var target = event.target
        target.style.zIndex = '101'
      },
      onend: function (event) {
        var target = event.target
        target.style.zIndex = '100'
      },
      onmove: function (event) {
        var target = event.target

        let multiplier = 1
        let parent = target.parentNode
        let transform = parent.style.transform
        if (transform.indexOf('scale') !== -1) {
          let scale = transform.substr(transform.indexOf('scale(') + 6)
          scale = scale.substr(0, scale.indexOf(')'))
          scale = parseFloat(scale)
          multiplier = 1 / scale
        }

        let curX = parseFloat(target.style.left.replace('px', ''))
        let curY = parseFloat(target.style.top.replace('px', ''))

        target.style.left = (curX + (event.dx * multiplier)).toFixed(2) + 'px'
        target.style.top = (curY + (event.dy * multiplier)).toFixed(2) + 'px'

        target.dispatchEvent(dragEvent)
      }
    })
  }
})
